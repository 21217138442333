import React, { useState, useEffect } from 'react';
import './Exam.css';

function Exam() {
  const [examType, setExamType] = useState([]);
  const [subExam, setSubExam] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [showStudentList, setShowStudentList] = useState(false);
  const [editingStudents, setEditingStudents] = useState([]);
  const [newMarks, setNewMarks] = useState({});
  const [selectedExamType, setSelectedExamType] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedSubExam, setSelectedSubExam] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchExamType();
    fetchTeachers();
  }, []);

  useEffect(() => {
    if (selectedExamType) {
      fetchSubExamTypes(selectedExamType);
    }
  }, [selectedExamType]);

  useEffect(() => {
    if (selectedTeacher) {
      fetchClasses(selectedTeacher);
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedTeacher && selectedClass) {
      fetchSections(selectedTeacher, selectedClass);
    }
  }, [selectedTeacher, selectedClass]);

  useEffect(() => {
    if (selectedTeacher && selectedClass && selectedSection) {
      fetchSubjects(selectedTeacher, selectedClass, selectedSection);
    }
  }, [selectedTeacher, selectedClass, selectedSection]);

  const fetchExamType = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlExam_Examination', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
      setExamType(data);
    } catch (error) {
      console.error('Error fetching exam types:', error);
    }
  };

  const fetchSubExamTypes = async (examCategoryId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlExamSubCategory_Examination', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ examCategoryId }),
      });
      const data = await response.json();
      setSubExam(data);
    } catch (error) {
      console.error('Error fetching sub-exam types:', error);
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetTeacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  const fetchClasses = async (teacherId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId }),
      });
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (teacherId, classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId, classId }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const fetchSubjects = async (teacherId, classId, sectionId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSubject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId, classId, sectionId }),
      });
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchStudents = async (details) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetStudentList_Examination', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(details),
      });
      const data = await response.json();
      setStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const validateForm = () => {
    return selectedExamType && selectedSubExam && selectedClass && selectedSection && selectedSubject;
  };

  const handleCheckboxChange = (rollNo) => {
    setEditingStudents(prevEditingStudents => {
      if (prevEditingStudents.includes(rollNo)) {
        return prevEditingStudents.filter(id => id !== rollNo);
      } else {
        return [...prevEditingStudents, rollNo];
      }
    });
    setNewMarks(prev => ({
      ...prev,
      [rollNo]: students.find(student => student.rollNo === rollNo)?.obtainedMarks || ''
    }));
  };

  const handleMarksChange = (e, rollNo) => {
    setNewMarks(prev => ({
      ...prev,
      [rollNo]: e.target.value
    }));
  };

  const handleSave = async () => {
    const payload = editingStudents.map((studentId) => {
      const student = students.find((student) => student.studentId === studentId);
      return {
        examTypeId: selectedExamType,
        studentId: student.studentId,
        classId: selectedClass,
        sectionId: selectedSection,
        subjectId: selectedSubject,
        maxMarks: student.maxMarks,
        minMarks: student.minMarks,
        obtainedMarks: newMarks[student.studentId] === '' ? 0 : newMarks[student.studentId],
        subExamCategoryId: selectedSubExam,
        isSubject: true,
        isAttendedExam: student.isAttendedExam
      };
    });

    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/SaveStudentMarks_Examination', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        alert('Marks saved successfully.');
        setStudents(prev =>
          prev.map(student =>
            editingStudents.includes(student.studentId)
              ? { ...student, obtainedMarks: newMarks[student.studentId] === '' ? 0 : newMarks[student.studentId] }
              : student
          )
        );
        setEditingStudents([]);
        setNewMarks({});
      } else {
        console.error('Error saving marks:', data);
        alert('Error saving marks. Please try again.');
      }
    } catch (error) {
      console.error('Error saving marks:', error);
      alert('Error saving marks. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditingStudents([]);
    setNewMarks({});
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const studentDetails = {
        classId: selectedClass,
        sectionId: selectedSection,
        subjectId: selectedSubject,
        examTypeId: selectedExamType,
        subCategoryId: selectedSubExam,
        isWithSubject: true,
      };

      await fetchStudents(studentDetails);
      setShowStudentList(true);
    } else {
      alert('Please fill in all the required fields.');
    }
  };

  return (
    <div className="exam-container">
      <h2>Examination Details</h2>

      <div className="form-group">
        <div className="form-row">
          <label>Exam Type:</label>
          <select value={selectedExamType} onChange={(e) => setSelectedExamType(e.target.value)}>
            <option value="">Select Exam Type</option>
            {examType.map((exam) => (
              <option key={exam.examTypeId} value={exam.examTypeId}>
                {exam.examType}
              </option>
            ))}
          </select>
        </div>

        <div className="form-row">
          <label>Sub Exam:</label>
          <select value={selectedSubExam} onChange={(e) => setSelectedSubExam(e.target.value)}>
            <option value="">Select Sub Exam</option>
            {subExam.map((sub) => (
              <option key={sub.examSubCategoryId} value={sub.examSubCategoryId}>
                {sub.examSubCategory}
              </option>
            ))}
          </select>
        </div>

        <div className="form-row">
          <label>Teacher:</label>
          <select value={selectedTeacher} onChange={(e) => setSelectedTeacher(e.target.value)}>
            <option value="">Select Teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher.employeeId} value={teacher.employeeId}>
                {teacher.employeeName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group">
        <div className="form-row">
          <label>Class:</label>
          <select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
            <option value="">Select Class</option>
            {classes.map((cls) => (
              <option key={cls.classId} value={cls.classId}>
                {cls.className}
              </option>
            ))}
          </select>
        </div>

        <div className="form-row">
          <label>Section:</label>
          <select value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)}>
            <option value="">Select Section</option>
            {sections.map((sec) => (
              <option key={sec.sectionId} value={sec.sectionId}>
                {sec.sectionName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-row">
          <label>Subject:</label>
          <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
            <option value="">Select Subject</option>
            {subjects.map((sub) => (
              <option key={sub.subjectId} value={sub.subjectId}>
                {sub.subjectName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button onClick={handleSubmit}>Get Student</button>

      {showStudentList && (
        <div className="student-list">
          <h3>Student List</h3>
          <input
          type="text"
          placeholder="Search students..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Name</th>
                <th>Roll No</th>
                <th>Class</th>
                <th>Section</th>
                <th>Subject</th>
                <th>Max Marks</th>
                <th>Min Marks</th>
                <th>Obtained Marks</th>
        
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.studentId}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(student.studentId)}
                      checked={editingStudents.includes(student.studentId)}
                    />
                  </td>
                  <td>{student.studentName}</td>
                  <td>{student.rollNo}</td>
                  <td>{student.className}</td>
                  <td>{student.section}</td>
                  <td>{student.subjectName}</td>
                  <td>{student.maxMarks}</td>
                  <td>{student.minMarks}</td>
                  <td>
                    <input
                      type="number"
                      value={editingStudents.includes(student.studentId) ? newMarks[student.studentId] || '' : student.obtainedMarks}
                      onChange={(e) => handleMarksChange(e, student.studentId)}
                      min="0"
                      max={student.maxMarks}
                      disabled={!editingStudents.includes(student.studentId)}
                    />
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </table>
          {editingStudents.length > 0 && (
            <div className="button-group">
              <button onClick={handleSave}>Save</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Exam;
