import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const LessonEdit = () => {
    const [showAddLessonCard, setShowAddLessonCard] = useState(false);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [lessonName, setLessonName] = useState('');
    const [lessonContent, setLessonContent] = useState('');
    const [lessonFile, setLessonFile] = useState(null);
    const [lessonFilePath, setLessonFilePath] = useState('');
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const [error, setError] = useState('');
    const [lessons, setLessons] = useState([]);

    const { lessonId } = useParams();

    // Fetch lesson data on component mount
    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher_Lessoon/GetLesson_Teacher', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: sessionStorage.getItem('token'),
                    },
                    body: JSON.stringify({}),
                });

                if (response.ok) {
                    const data = await response.json();
                    setLessons(data);
                    // Find the lesson with the matching lessonId
                    const lessonToEdit = data.find(lesson => lesson.lessonId === parseInt(lessonId));
                    console.log("lessondata:", lessonToEdit);
                    if (lessonToEdit) {  
                        setSelectedClass(lessonToEdit.classId);
                        setSelectedSection(lessonToEdit.subjectId);
                        setLessonName(lessonToEdit.lessonName);
                        setLessonContent(lessonToEdit.lessonContent);
                        setLessonFilePath(lessonToEdit.lessionPdfUrl);
                    } else {
                        setError('Lesson not found.');
                    }
                } else {
                    setError('Failed to fetch lesson details.');
                }
            } catch (error) {
                console.error('Error fetching lessons:', error);
                setError('Error fetching lesson details.');
            }
        };

        fetchLessons();
    }, [lessonId]);

    const handleSaveLesson = async () => {
        if (!selectedClass || !selectedSection || !lessonName || !lessonContent || !lessonFilePath) {
            setError('All fields are required, including the lesson file.');
            return;
        }

        setError('');

        const lessonData = {
            classId: selectedClass,
            subId: selectedSection,
            lessonName,
            lessonContent,
            lessionPdfUrl: lessonFilePath,
        };

        try {
            const response = await fetch(`https://arizshad-002-site5.ktempurl.com/api/Teacher_Lessoon/Id?Id=${lessonId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify(lessonData),
            });

            if (response.ok) {
                alert('Lesson updated successfully');
                clearForm();
                setShowAddLessonCard(false);
            } else {
                setError('Failed to save the lesson.');
            }
        } catch (error) {
            console.error('Error saving lesson:', error);
            setError('Error saving lesson.');
        }
    };

    const handleCloseCard = () => {
        setShowAddLessonCard(false);
        clearForm();
    };

    const clearForm = () => {
        setSelectedClass('');
        setSelectedSection('');
        setLessonName('');
        setLessonContent('');
        setLessonFile(null);
        setLessonFilePath('');
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            setError('File size must be less than 2 MB.');
            return;
        }

        setLessonFile(file);
        await uploadLessonFile(file);
    };

    const uploadLessonFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('teacherId', sessionStorage.getItem('employeeId'));

        try {
            const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher_Lessoon/LessonFileUpload', {
                method: 'POST',
                headers: {
                    Authorization: sessionStorage.getItem('token'),
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setLessonFilePath(data.filePath);
                setError('');
            } else {
                setError('Failed to upload the file.');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file.');
        }
    };

    useEffect(() => {
        fetchClasses();
    }, []);

    useEffect(() => {
        if (selectedClass) {
            fetchSections(selectedClass);
        } else {
            setSections([]);
        }
    }, [selectedClass]);

    const fetchClasses = async () => {
        try {
            const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify({
                    teacherId: sessionStorage.getItem('employeeId'),
                }),
            });
            const data = await response.json();
            if (data.data === null && data.msg === "Record Not Found") {
                throw new Error("Record Not Found");
              }
            setClasses(data);
        } catch (error) {
            console.error('Error fetching classes:', error);
        }
    };

    const fetchSections = async (classId) => {
        try {
            const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Question/ddlSubject', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify({
                    classId,
                }),
            });
            const data = await response.json();
            setSections(data);
        } catch (error) {
            console.error('Error fetching sections:', error);
        }
    };

    return (
        <div>
            <div className="lessons-add-card">
                <button className="lessons-close-button" onClick={handleCloseCard}>×</button>
                <h1>Edit Lesson</h1>
                {error && <p className="lessons-error-message">{error}</p>}
                <div className="lessons-form-group lessons-form-group-inline">
                    <div>
                        <label>Class</label>
                        <select
                            value={selectedClass}
                            onChange={(e) => setSelectedClass(e.target.value)}
                        >
                            <option value="">Select Class</option>
                            {classes.map((cls) => (
                                <option key={cls.classId} value={cls.classId}>
                                    {cls.className}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Subject</label>
                        <select
                            value={selectedSection}
                            onChange={(e) => setSelectedSection(e.target.value)}
                            disabled={!selectedClass}
                        >
                            <option value="">Select Subject</option>
                            {sections.map((sec) => (
                                <option key={sec.subjectId} value={sec.subjectId}>
                                    {sec.subjectName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="lessons-form-group">
                    <label>Lesson Name</label>
                    <input
                        type="text"
                        value={lessonName}
                        onChange={(e) => setLessonName(e.target.value)}
                        placeholder="Enter Lesson Name"
                    />
                </div>
                <div className="lessons-form-group">
                    <label>Lesson Content</label>
                    <textarea
                        value={lessonContent}
                        onChange={(e) => setLessonContent(e.target.value)}
                        placeholder="Enter Lesson Content"
                    />
                </div>
                <div className="lessons-form-group">
                    <label>Lesson Path (Max 2 MB, PDF only)</label>
                    <input
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                    />
                    {lessonFilePath && (
                        <p>
                            Current File: <a href={`https://arizshad-002-site5.ktempurl.com/${lessonFilePath}`} target="_blank" rel="noopener noreferrer">{lessonFilePath.split('/').pop()}</a>
                        </p>
                    )}
                </div>
                <button className="lessons-save-button" onClick={handleSaveLesson}>
                    Update Lesson
                </button>
            </div>
        </div>
    );
};

export default LessonEdit;
