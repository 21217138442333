import React, { useState, useEffect } from 'react';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './AttendanceChart.css';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip);

const AttendanceChart = () => {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [viewType, setViewType] = useState('daily'); // Default to daily view
  const [selectedMonth, setSelectedMonth] = useState(null); // Dynamic month selection
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const fetchChartData = async () => {
    let apiUrl = '';
    let payload = {
      classId: selectedClass,
      sectionId: selectedSection,
      monthId: selectedMonth, // Use selected month ID for the API
    };

    switch (viewType) {
      case 'daily':
        apiUrl = 'https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/TeacherAttendanceRecord_CurrentDay';
        break;
      case 'weekly':
        apiUrl = 'https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/TeacherAttendanceRecord_CurrentWeek';
        break;
      case 'monthly':
        apiUrl = 'https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/TeacherAttendanceRecord_SelectedMonth';
        break;
      case 'currentMonth':
        apiUrl = 'https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/TeacherAttendanceRecord_CurrentMonth';
        break;
      default:
        return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const labels = data.data.map(item => item.day);
        const presentData = data.data.map(item => item.presentStudentsCount);
        const absentData = data.data.map(item => item.absentStudentsCount);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Present Students',
              data: presentData,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Absent Students',
              data: absentData,
              backgroundColor: 'rgba(255, 99, 132, 0.6)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            }
          ],
        });
      } else {
        console.error('Failed to fetch attendance data');
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  };

  const fetchClasses = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
        }),
      });
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
          classId,
        }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const fetchMonths = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Fine/GetMonthList', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMonths(data);
        setSelectedMonth(data[0]?.monthId); // Set default selected month
      } else {
        console.error('Failed to fetch months');
      }
    } catch (error) {
      console.error('Error fetching months:', error);
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchMonths();
  }, []);

  useEffect(() => {
    if (selectedClass) {
      fetchSections(selectedClass);
    } else {
      setSections([]);
    }
  }, [selectedClass]);

  useEffect(() => {
    if (selectedClass && selectedSection) {
      fetchChartData();
    }
  }, [selectedClass, selectedSection, viewType, selectedMonth]);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Attendance Overview - ${viewType.charAt(0).toUpperCase() + viewType.slice(1)} ${viewType === 'monthly' || viewType === 'currentMonth' ? months.find(month => month.monthId === selectedMonth)?.month : ''}`,
        padding: {
          top: 10,
          bottom: 30,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Students',
        },
      },
      x: {
        title: {
          display: true,
          text: viewType === 'monthly' ? 'Days' : 'Dates',
        },
      },
    },
  };

  return (
    <div className="attendance-chart-container">
      {viewType === 'monthly' && (
        <div className="month-options">
          {months.map((month) => (
            <button
              key={month.monthId}
              className={`month-option ${selectedMonth === month.monthId ? 'active' : ''}`}
              onClick={() => setSelectedMonth(month.monthId)}
            >
              {month.month}
            </button>
          ))}
        </div>
      )}

      <h2 id="attendance-overview">Attendance Overview</h2>

      <div className="view-options">
        <label>
          <input
            type="radio"
            name="viewType"
            value="daily"
            checked={viewType === 'daily'}
            onChange={() => setViewType('daily')}
          />
          Current Day
        </label>
        <label>
          <input
            type="radio"
            name="viewType"
            value="weekly"
            checked={viewType === 'weekly'}
            onChange={() => setViewType('weekly')}
          />
          Current Week
        </label>
        <label>
          <input
            type="radio"
            name="viewType"
            value="monthly"
            checked={viewType === 'monthly'}
            onChange={() => setViewType('monthly')}
          />
          Selected Month
        </label>
        <label>
          <input
            type="radio"
            name="viewType"
            value="currentMonth"
            checked={viewType === 'currentMonth'}
            onChange={() => setViewType('currentMonth')}
          />
          Current Month
        </label>
      </div>

      <div className="select-container">
        <select
          name="className"
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          required
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls.classId} value={cls.classId}>
              {cls.className}
            </option>
          ))}
        </select>

        <select
          name="section"
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
          required
        >
          <option value="">Select Section</option>
          {sections.map((sec) => (
            <option key={sec.sectionId} value={sec.sectionId}>
              {sec.sectionName}
            </option>
          ))}
        </select>
      </div>

      <div className="chart-wrapper">
        <div className="chart-container">
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default AttendanceChart;
