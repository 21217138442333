import React, { useState, useEffect } from 'react';
import './CreateAssignment.css';

function CreateAssignment() {
  const [assignment, setAssignment] = useState({
    subject: '',
    className: '',
    section: '',
    creationDate: new Date().toISOString().slice(0, 10),
    submissionDate: '',
    details: '',
    files: [],
  });

  const [notification, setNotification] = useState('');
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    if (assignment.className) {
      fetchSections(assignment.className);
    }
  }, [assignment.className]);

  useEffect(() => {
    if (assignment.className && assignment.section) {
      fetchSubjects(assignment.className, assignment.section);
    }
  }, [assignment.className, assignment.section]);

  const fetchClasses = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
        }),
      });
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
          classId,
        }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const fetchSubjects = async (classId, sectionId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSubject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
          classId,
          sectionId,
        }),
      });
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssignment((prevAssignment) => ({
      ...prevAssignment,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAssignment((prevAssignment) => ({
      ...prevAssignment,
      files: [...prevAssignment.files, ...files],
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const postAssignmentNotification = async (assignmentId) => {
    const userId = sessionStorage.getItem('employeeId');
    const message = `A new assignment has been created for ${assignment.className} - ${assignment.section}`;
    const clientName = sessionStorage.getItem('clientName');
    const name = clientName.replace(/['"`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '');

    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Notifications/PostAssignmentNotification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ userId, message, name }),
      });

      if (response.ok) {
        console.log('Notification sent successfully.');
      } else {
        console.error('Failed to send notification.');
      }
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { subject, className, section, creationDate, submissionDate, details, files } = assignment;
    if (!subject || !className || !section || !creationDate || !submissionDate || !details) {
      setNotification('All fields are required.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('ClassId', className);
      formData.append('SectionId', section);
      formData.append('SubjectId', subject);
      formData.append('TeacherId', sessionStorage.getItem('employeeId'));
      formData.append('AssignmentDescription', details);
      formData.append('AssignmentDate', formatDate(creationDate));
      formData.append('AssignmentSubmitDate', formatDate(submissionDate));
      files.forEach((file, index) => {
        formData.append(`AssignMentFile`, file);
      });

      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/CreateAssignment', {
        method: 'POST',
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
        body: formData,
      });

      if (response.ok) {
        setNotification('Assignment has been created successfully!');
        postAssignmentNotification(); // Call notification function if assignment creation is successful
      } else {
        setNotification('Failed to create assignment.');
      }
    } catch (error) {
      console.error('Error creating assignment:', error);
      setNotification('An error occurred while creating the assignment.');
    }

    setTimeout(() => {
      setNotification('');
    }, 3000);
  };

  return (
    <div className="create-assignment">
      <h2>Create Assignment</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>Class:</label>
            <select name="className" value={assignment.className} onChange={handleChange} required>
              <option value="">Select Class</option>
              {classes.map((cls) => (
                <option key={cls.classId} value={cls.classId}>
                  {cls.className}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Section:</label>
            <select name="section" value={assignment.section} onChange={handleChange} required>
              <option value="">Select Section</option>
              {sections.map((sec) => (
                <option key={sec.sectionId} value={sec.sectionId}>
                  {sec.sectionName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Subject:</label>
            <select name="subject" value={assignment.subject} onChange={handleChange} required>
              <option value="">Select Subject</option>
              {subjects.map((sub) => (
                <option key={sub.subjectId} value={sub.subjectId}>
                  {sub.subjectName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group upload-group">
            <label>Upload Files (PDF, JPEG, PNG, JPG):</label>
            <input type="file" accept=".pdf,.jpeg,.png,.jpg" multiple onChange={handleFileChange} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Creation Date:</label>
            <input type="date" name="creationDate" value={assignment.creationDate} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Submission Date:</label>
            <input type="date" name="submissionDate" value={assignment.submissionDate} onChange={handleChange} required />
          </div>
        </div>
        <div className="form-group">
          <label>Details:</label>
          <textarea name="details" value={assignment.details} onChange={handleChange} required />
        </div>
        <div className="button-container">
          <button type="submit">Create Assignment</button>
        </div>
      </form>
      {notification && <div className="notification">{notification}</div>}
    </div>
  );
}

export default CreateAssignment;
