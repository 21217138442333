import React, { useState, useEffect } from 'react';
import './ViewAttendance.css';
import StudentDetailModal from './StudentDetailModal';

function ViewAttendance() {
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentProfile, setStudentProfile] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const attendanceData = {
    // ... (same as before)
  };

  const handleOpenModal = (student) => {
    setSelectedStudent(student.studentId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedStudent(null);
    setIsModalOpen(false);
  };

  const fetchTeachers = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetTeacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  const fetchClasses = async (teacherId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId,
        }),
      });
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: selectedTeacher,
          classId,
        }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const fetchAttendance = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetStudentAdmissionDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          classId: selectedClass,
          sessionId: 0,
        }),
      });
      const data = await response.json();
      setStudents(data);
    } catch (error) {
      console.error('Error fetching attendance:', error);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  useEffect(() => {
    if (selectedTeacher) {
      fetchClasses(selectedTeacher);
    } else {
      setClasses([]);
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedClass) {
      fetchAttendance(selectedClass);
    } else {
      setSections([]);
    }
  }, [selectedClass]);

  return (
    <div className="view-attendance">
      <h1>Student Attendance</h1>
      <div className="filter-container">
        <select
          value={selectedTeacher}
          onChange={(e) => setSelectedTeacher(e.target.value)}
        >
          <option value="">Select Teacher</option>
          {teachers.map((teacher) => (
            <option key={teacher.employeeId} value={teacher.employeeId}>
              {teacher.employeeName}
            </option>
          ))}
        </select>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          disabled={!selectedTeacher}
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls.classId} value={cls.classId}>
              {cls.className}
            </option>
          ))}
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Serial No</th>
            <th>Admission No</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Roll No</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={student.id}>
              <td>{index + 1}</td>
              <td>{student.admissionNo}</td>
              <td>{student.studentName}</td>
              <td>{student.studentLastName}</td>
              <td>{student.rollNo}</td>
              <td>
                <button  onClick={() => handleOpenModal(student)}>View Details</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <StudentDetailModal isOpen={isModalOpen} handleClose={handleCloseModal} studentId={selectedStudent} />
    </div>
  );
}

export default ViewAttendance;
