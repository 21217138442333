import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const TopicEdit = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddTopicCard, setShowAddTopicCard] = useState(false);
  const [showMasterPage, setShowMasterPage] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [topicHeading, setTopicHeading] = useState('');
  const [topicContent, setTopicContent] = useState('');
  const [topicPath, setTopicPath] = useState(null);
  const [error, setError] = useState('');
  const [topics, setTopics] = useState([]);
  

const { topicId } = useParams();


useEffect(() => {
    const fetchTopics = async () => {
        try {
            const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher_Topic/GetTopic_Teacher', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify({}),
            });

            if (response.ok) {
                const data = await response.json();
                setTopics(data);
                // Find the lesson with the matching lessonId
                const topicToEdit = data.find(lesson => lesson.topicId === parseInt(topicId));
                if (topicToEdit) {  
                    setSelectedClass(topicToEdit.classId);
                    setSelectedSubject(topicToEdit.subjectId);
                    setVideoLink(topicToEdit.videoUrlLink);
                    setTopicContent(topicToEdit.content);
                    setTopicPath(topicToEdit.pdfPath);

                } else {
                    setError('Lesson not found.');
                }
            } else {
                setError('Failed to fetch lesson details.');
            }
        } catch (error) {
            console.error('Error fetching lessons:', error);
            setError('Error fetching lesson details.');
        }
    };

    fetchTopics();
}, [topicId]);


  const handleSaveTopic = async () => {
    if (!selectedClass || !selectedSubject || !videoLink || !topicHeading || !topicContent || !topicPath) {
      setError('All fields are required.');
      return;
    }

    if (topicPath && topicPath.size > 2 * 1024 * 1024) {
      setError('File size should not exceed 2 MB.');
      return;
    }

    const formData = new FormData();
    formData.append('CourseId', selectedClass);
    formData.append('SubjectId', selectedSubject);
    formData.append('Topic', topicHeading);
    formData.append('LessionId', topics.length + 1);  // Assuming lesson ID is incremented by the topic count
    formData.append('Content', topicContent);
    formData.append('TopicPath', topicPath);
    formData.append('VideoLink', videoLink);

    try {
      const response = await fetch(`https://arizshad-002-site5.ktempurl.com/api/Teacher_Topic/Id?Id=${topicId}`, {
        method: 'PUT',
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
        body: formData,
      });

      if (response.ok) {
        alert('Topic saved successfully');
        // Handle successful response
        const newTopic = {
          id: topics.length + 1,
          className: selectedClass,
          topic: topicHeading,
          subjectName: selectedSubject,
          lessonName: videoLink,
        };
        setTopics([...topics, newTopic]);

        // Reset the form
        setSelectedClass('');
        setSelectedSubject('');
        setVideoLink('');
        setTopicHeading('');
        setTopicContent('');
        setTopicPath(null);
        setError('');
        setShowAddTopicCard(false);
        setShowMasterPage(false);
      } else {
        // Handle error response
        setError('Failed to save the topic. Please try again.');
      }
    } catch (error) {
      console.error('Error saving topic:', error);
      setError('An error occurred while saving the topic. Please try again.');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== 'application/pdf') {
      setError('Only PDF files are allowed.');
    } else {
      setTopicPath(file);
      setError('');
    }
  };

  const handleCancel = () => {
    setShowAddTopicCard(false);
    setShowMasterPage(false);
    setSelectedClass('');
    setSelectedSubject('');
    setVideoLink('');
    setTopicHeading('');
    setTopicContent('');
    setTopicPath(null);
    setError('');
  };

  const fetchClasses = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: sessionStorage.getItem('employeeId'),
        }),
      });
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSubject = async (classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Question/ddlSubject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          classId,
        }),
      });
      const data = await response.json();
      setSubject(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    if (selectedClass) {
      fetchSubject(selectedClass);
    } else {
      setSubject([]);
    }
  }, [selectedClass]);

  return (
    <div className="topics-add-card">
      <h1>Topic Master Page</h1>
      {error && <p className="topics-error-message">{error}</p>}
      <div className="topics-form-group topics-form-group-inline">
        <div>
          <label>Class</label>
          <select
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
          >
            <option value="">Select Class</option>
            {classes.map((cls) => (
              <option key={cls.classId} value={cls.classId}>
                {cls.className}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Subject</label>
          <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
            <option value="">Select Subject</option>
            {subject.map((sub) => (
              <option key={sub.subjectId} value={sub.subjectId}>
                {sub.subjectName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="topics-form-group">
        <label>Video Link</label>
        <input
          type="text"
          value={videoLink}
          onChange={(e) => setVideoLink(e.target.value)}
          placeholder="Enter Video Link"
        />
      </div>
      <div className="topics-form-group">
        <label>Topic Heading</label>
        <input
          type="text"
          value={topicHeading}
          onChange={(e) => setTopicHeading(e.target.value)}
          placeholder="Enter Topic Heading"
        />
      </div>
      <div className="topics-form-group">
        <label>Topic Content</label>
        <ReactQuill
          value={topicContent}
          onChange={setTopicContent}
          placeholder="Enter Topic Content"
        />
      </div>
      <div className="topics-form-group">
        <label>Topic Path (Max 2 MB, PDF only)</label>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
        {topicPath && (
                        <p>
                            Current File: <a href={`https://arizshad-002-site5.ktempurl.com/${topicPath}`} target="_blank" rel="noopener noreferrer">{topicPath.split('/').pop()}</a>
                        </p>
                    )}
      </div>
      <div className="topics-form-group-buttons">
        <button className="topics-save-button" onClick={handleSaveTopic}>
          Save
        </button>
        <button className="topics-cancel-button" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TopicEdit;
