import React, { useState, useEffect } from 'react';
import './Classes.css';
import cassImage from './cass.png'; 

const Classes = () => {
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/Dashboard_ClassCard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
        });
        const data = await response.json();

        if (data.data === null && data.msg === "Record Not Found") {
          throw new Error("Record Not Found");
          setClasses([]);
          return;
        }
        // Transform data
        const classesMap = {};

        data.list.forEach(item => {
          if (!classesMap[item.className]) {
            classesMap[item.className] = {
              className: item.className,
              sections: []
            };
          }
          
          classesMap[item.className].sections.push({
            sectionName: item.sectionName,
            studentCount: item.studentCount
          });
        });

        setClasses(Object.values(classesMap));
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  return (
    <div className="card-flip">
      <div className="card-flip-inner">
        <div className="card-flip-front">
          <img src={cassImage} alt="cass" />
          <h2>Classes</h2>
        </div>
        <div className="card-flip-back">
          {classes.map((classItem, index) => (
            <div key={index} className="class-info">
              <h3>{classItem.className}</h3>
              {classItem.sections.map((section, secIndex) => (
                <p key={secIndex}>
                  <strong>{section.sectionName}:</strong> {section.studentCount} students
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Classes;
